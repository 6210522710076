.main {
  cursor: default;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: monospace;
  font-weight: 500;
  color: white;

  --bg-transition-speed: calc(var(--animation-speed) * 1.4);
}

.main::after,
.main::before {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: -2;
  transition: clip-path var(--bg-transition-speed) linear
    var(--bg-transition-speed);
}
.main::before {
  clip-path: circle(0 at 0 50vh);
  background: linear-gradient(
    var(--mantine-color-red-5),
    var(--mantine-color-red-6)
  );
}
.main::after {
  clip-path: circle(0 at 100vw 50vh);
  background: linear-gradient(
    var(--mantine-color-green-5),
    var(--mantine-color-green-8)
  );
}
.main.rest::after,
.main.focus::before {
  z-index: -1;
  transition: clip-path var(--bg-transition-speed) linear;
  clip-path: circle(calc(49vh + 49vw) at 50vw 50vh);
}

@keyframes zoomIn {
  from {
    transform: scale(0.5);
  }
  to {
    opacity: 0;
  }
}

.playBox,
.pauseBox {
  position: absolute;
  background: rgba(0, 0, 0, 0.1);
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  aspect-ratio: 1;
  animation: zoomIn 1s linear forwards;
  display: none;
}

.playBox.active,
.pauseBox.active {
  display: flex;
}

.ring {
  filter: drop-shadow(4px 2px 2px #0003);
}

.menu {
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(0);
  transition: all var(--animation-speed) ease;
  filter: drop-shadow(2px 1px 1px #0004);
}

.menu.hidden {
  transform: translateX(4rem);
}

.actionIcon {
  padding: 0.5rem;
  margin: 0.5rem;
  display: flex;
  border-radius: 1.5rem;
  -webkit-tap-highlight-color: transparent;
  transition: background-color var(--animation-speed);
}
.actionIcon:active {
  background: rgba(255, 255, 255, 0.2);
}
