.modalTips {
  --_inner-align: flex-end;
  text-transform: uppercase;
}

.modalTips :global(.mantine-Modal-content) {
  background-color: color-mix(
    in srgb,
    var(--mantine-color-body) 50%,
    transparent
  );
}

.modalTips :global(.mantine-Modal-header) {
  background: none;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
}

@media (prefers-color-scheme: dark) {
  .modalTips {
    --text-color: white;
  }
}

@keyframes slideUpDoown {
  from,
  to {
    transform: translateY(0px);
  }
  30%,
  70% {
    transform: translateY(-30px);
  }
}

.modalTips.animate :global(.mantine-Modal-content) {
  animation-duration: 1s;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-name: slideUpDoown;
  transform-origin: center bottom;
}

.modalInstallIcon {
  width: 3.5rem;
  height: 3.5rem;
}

.modalInstalliOSIcon {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 15%;
}

.iconShare {
  fill: #1784f5;
  width: 1.5rem;
  height: 1.5rem;
}

.iconAdd {
  fill: #1784f5;
  width: 1.25rem;
  height: 1.25rem;
  margin: 0 0.15rem;
}
