html,
body {
  height: 100svh;
  width: 100vw;
  overflow: hidden;

  /* to disable pull to refresh on mobile */
  overscroll-behavior: none;

  /* to disable user selection on long touch */
  user-select: none;
}
