.main {
  cursor: default;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: white;
  background: linear-gradient(
    var(--mantine-color-indigo-5),
    var(--mantine-color-indigo-9)
  );
}

.main a {
  color: white;
  font-weight: 500;
}
