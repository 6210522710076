.tutorial {
  --show-tutorial-delay: 0.4s;
  --show-text-delay: calc(
    var(--show-tutorial-delay) + var(--animation-speed) * 2
  );
}

.tips {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  background: radial-gradient(
    circle at 50%,
    transparent,
    transparent 160px,
    rgba(0, 0, 0, 0.5) 160px,
    rgba(0, 0, 0, 0.5)
  );
  animation: scale calc(var(--animation-speed) * 2) 1 var(--show-tutorial-delay)
    backwards cubic-bezier(0.65, 0, 0.35, 1);
}

@keyframes scale {
  from {
    transform: scale(7);
  }
}

.text {
  top: 50%;
  left: 0;
  right: 0;
  margin-inline: auto;
  position: absolute;
  margin-top: -280px;
  animation: show var(--animation-speed) 1 var(--show-text-delay) backwards
    cubic-bezier(0.65, 0, 0.35, 1);
}

.skip {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin-bottom: 5rem;
  animation: show var(--animation-speed) 1 var(--show-text-delay) backwards
    cubic-bezier(0.65, 0, 0.35, 1);
}

@keyframes show {
  from {
    opacity: 0;
  }
}

.swipeLeft {
  animation: swipe-left 1s infinite;
}
.swipeRight {
  animation: swipe-right 1s infinite;
}
.swipeDown {
  animation: swipe-down 1s infinite;
}
.swipeUp {
  animation: swipe-up 1s infinite;
}
.opacity {
  animation: opacity 1s infinite;
}

@keyframes swipe-left {
  to {
    transform: translateX(-30px);
  }
}
@keyframes swipe-right {
  to {
    transform: translateX(30px);
  }
}
@keyframes swipe-down {
  to {
    transform: translateY(30px);
  }
}
@keyframes swipe-up {
  from {
    transform: translateY(30px);
  }
}
@keyframes opacity {
  to {
    opacity: 0;
  }
}
