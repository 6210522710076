.main {
  --padding-top: 5rem;

  height: 100svh;
  width: 100vw;
  overflow: auto;
  padding-inline: 1.5rem;
  padding-top: var(--padding-top);
  scroll-padding: var(--padding-top);
  margin-inline: auto;
  background: var(--mantine-color-body);
  scroll-snap-type: y mandatory;
}

.navbar {
  position: absolute;
  top: 0;
  z-index: 9;
  left: 0;
  right: 0;
  background: transparent;
}

.navbar:after {
  position: absolute;
  z-index: -1;
  height: 5rem;
  pointer-events: none; /* for scroll to work over it */
  content: "";
  width: 100vw;
  background: linear-gradient(
    var(--mantine-color-body),
    color-mix(in srgb, var(--mantine-color-body) 50%, transparent),
    transparent
  );
}

.navTitle {
  display: none;
  opacity: 0;
}

.navbar.scrolled .navTitle {
  transition: display var(--animation-speed), opacity var(--animation-speed);
  transition-delay: var(--animation-speed);
  transition-behavior: allow-discrete;
  display: block;
  opacity: 1;
}

@starting-style {
  .navbar.scrolled .navTitle {
    opacity: 0;
  }
}

.navbar.scrolled {
  background: #373a40; /* TODO: 56 make consistent */
}
.navbar.scrolled::after {
  display: none;
}

.titleBox,
.snapPoint {
  max-width: var(--mantine-breakpoint-xs);
  margin-inline: auto;
}

.title {
  scroll-snap-align: start;
}

@supports (animation-timeline: view()) {
  .title {
    animation-name: fadeOut;
    animation-timing-function: linear;
    animation-fill-mode: both;
    animation-timeline: view();
    animation-range: exit;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.snapPoint {
  scroll-snap-align: start;
}

.main :global(.mantine-Switch-labelWrapper) {
  flex: 1;
}

.main :global(.mantine-NativeSelect-root) {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.main :global(.mantine-NativeSelect-input) {
  color: var(--mantine-color-red-filled);
  text-align: right;
}

/* .main :global(.mantine-Chip-label) {
  --_chip-padding: var(--chip-checked-padding);
}

.main :global(.mantine-Chip-iconWrapper) {
  display: none;
} */

.main :global(.mantine-Slider-markLabel) {
  transform: translate(
    calc(-50% + var(--slider-size) / 2),
    calc(var(--mantine-spacing-md))
  );
}
.main :global(.mantine-Slider-root) {
  padding-bottom: 2rem;
}

.main :global(.mantine-Slider-label) {
  display: none;
}

.actionIcon {
  padding: 0.5rem;
  margin: 0.5rem;
  display: flex;
  border-radius: 1.5rem;
  -webkit-tap-highlight-color: transparent;
  transition: background-color var(--animation-speed);
}
.actionIcon:active {
  background: color-mix(
    in srgb,
    var(--mantine-color-default-color) 20%,
    transparent
  );
}

.mark {
  font-size: 1rem;
  line-height: 1;
}
